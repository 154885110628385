<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Contas a Pagar/Receber</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        v-model="search"
                        @keypress.enter="consultar"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                    />
                    <v-spacer/>
                    <ButtonAddNew @click="openCadastro" />
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 220"
                            @click:row="editar"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :options.sync="options"
                        >
                            <template v-slot:item.valor="{ item }">
                                <template>
                                    <span>R${{ formatPrice(item.valor) }}</span>
                                </template>
                            </template>
                            <template v-slot:item.created_at="{ item }">
                                <template>
                                    <span>{{ formatDate(item.created_at) }}</span>
                                </template>
                            </template>
                            <template v-slot:item.vencimento="{ item }">
                                <template>
                                    <span>{{ formatDate(item.vencimento, 'DD/MM/YYYY') }}</span>
                                </template>
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-btn icon @click.stop="excluir(item.id)" color="red">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                        <Paginate
                            v-model="pagination"
                            @input="consultar"
                        />
                    </v-card-text>
                </v-card>
            </v-content>
            <ContaPagarReceber
                :visible="visible"
                :formData="form"
                @onCadastro="onCadastro"
                @close="close"
            />
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import ButtonAddNew from '@/components/ButtonAddNew';
import Paginate from '@/components/Paginate';
import ContaPagarReceber from '@/pages/cadastro/ContaPagarReceber';

export default {
    name: 'ContasPagarReceber',

    components: {
        BaseContainer,
        ButtonAddNew,
        Paginate,
        ContaPagarReceber,
    },

    data: () => ({
        visible: false,
        search: '',
        loading: true,
        dados: [],
        form: {},
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Titulo', value: 'titulo' },
            { text: 'Tipo', value: 'tipo' },
            { text: 'Valor', value: 'valor' },
            { text: 'Data', value: 'created_at' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Status', value: 'status' },
            { text: '', value: 'action', sortable: false, align: 'end'},
        ],
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        options: {},
    }),

    mounted() {
        this.consultar();
    },

    watch: {
        options: {
            handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = [];

            const sortBy = this.options.sortBy.length ? `&sortField=${this.options.sortBy[0]}` : '';
            const sortOrder = this.options.sortDesc.length ? `&sortOrder=${this.options.sortDesc[0] ? 1 : -1}` : '';
            const search = `&q=${this.search || ''}`;

            const params = `page=${this.pagination.current_page}${sortBy}${sortOrder}${search}`;

            this.$http.get(`contas-pagar-receber?${params}`).then(resp => {
                this.pagination = resp.data.meta;
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
                this.loading = false;
            });
        },

        close() {
            this.visible = false;
        },

        openCadastro() {
            this.form = { tipo: 'credito' };
            this.visible = true;
        },

        onCadastro() {
            this.close();
            this.consultar();
        },

        editar(row) {
            this.form = row;
            this.visible = true;
        },

        abrirWhatsapp(whats) {
            const phone = '55' + whats.replace(/\D/g, '');
            window.open(`https://api.whatsapp.com/send?phone=${phone}`);
        },
    },
}
</script>
